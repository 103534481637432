/* ServicesSection.css */
.icon {
    width: 80px; /* Set the desired width */
    height: 60px; /* Set the desired height */
    object-fit: cover; /* Ensures images cover the area without distortion */
  }
  
 
  
  .service-block {
    /* Specific styles for each service block */
    text-align: center; /* Center the text */
    margin: 10px; /* Space between service blocks */
  }