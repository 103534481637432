.latest-news-transition {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.news-container {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 150px;
}

.news-item {
  flex-shrink: 0;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
  display: none;
}

.news-item.active {
  display: block;
}

.news-item a {
  color: #1ab5f1;
  font-size: 1.5rem;
  text-decoration: none;
}

.news-date {
  font-size: 0.8rem;
  color: #666;
  margin-left: 10px;
}

.latest-news-label {
  position: relative;
  top: 50%;
  left: 0;
  transform: translateY(-80%);
  background-color: #1ab5f1;
  color: white;
  font-weight: bolder;
  padding: 10px;
  font-size: 1.5rem;
  width: 20%;
  text-align: center;
  margin-bottom: 0;
}

.shortnews{
  padding: 5px 50px;
  position: relative;
  background: #c5e3ef9c;
  color: rgba(2, 0, 10, 0.824);
  font-size: 1.5rem;
  font-weight: 400px;
  margin-top: 0;
}
