	/*
  	Flaticon icon font: Flaticon
  	Creation date: 11/08/2020 03:36
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-style: normal;
}

.flaticon-right:before { content: "\f100"; }
.flaticon-search:before { content: "\f101"; }
.flaticon-right-arrow:before { content: "\f102"; }
.flaticon-tick:before { content: "\f103"; }
.flaticon-right-arrow-1:before { content: "\f104"; }
.flaticon-backward:before { content: "\f105"; }
.flaticon-clock:before { content: "\f106"; }
.flaticon-star:before { content: "\f107"; }
.flaticon-null:before { content: "\f108"; }
.flaticon-arrow:before { content: "\f109"; }
.flaticon-share:before { content: "\f10a"; }
.flaticon-remove:before { content: "\f10b"; }
.flaticon-signs:before { content: "\f10c"; }
.flaticon-ui:before { content: "\f10d"; }
.flaticon-arrows:before { content: "\f10e"; }
.flaticon-add:before { content: "\f10f"; }
.flaticon-angle-arrow-pointing-to-right:before { content: "\f110"; }
.flaticon-null-1:before { content: "\f111"; }
.flaticon-gps:before { content: "\f112"; }
.flaticon-search-1:before { content: "\f113"; }
.flaticon-phone:before { content: "\f114"; }
.flaticon-long-right-arrow:before { content: "\f115"; }
.flaticon-long-arrow-pointing-to-the-right:before { content: "\f116"; }
.flaticon-talk:before { content: "\f117"; }
.flaticon-comment:before { content: "\f118"; }
.flaticon-long-arrow-pointing-to-left:before { content: "\f119"; }
.flaticon-spray-gun:before { content: "\f11a"; }
.flaticon-tire:before { content: "\f11b"; }
.flaticon-car-engine:before { content: "\f11c"; }
.flaticon-power:before { content: "\f11d"; }
.flaticon-gearbox:before { content: "\f11e"; }
.flaticon-car:before { content: "\f11f"; }
.flaticon-car-1:before { content: "\f120"; }
.flaticon-maintenance:before { content: "\f121"; }
.flaticon-price-tag:before { content: "\f122"; }
.flaticon-repair:before { content: "\f123"; }
.flaticon-brake-disc:before { content: "\f124"; }
.flaticon-trophy:before { content: "\f125"; }
.flaticon-wrench:before { content: "\f126"; }
.flaticon-maintenance-1:before { content: "\f127"; }
.flaticon-mechanic:before { content: "\f128"; }
.flaticon-price-tag-1:before { content: "\f129"; }
.flaticon-customer-service:before { content: "\f12a"; }
.flaticon-car-service:before { content: "\f12b"; }
.flaticon-car-2:before { content: "\f12c"; }
.flaticon-drive-thru:before { content: "\f12d"; }
.flaticon-air-conditioning:before { content: "\f12e"; }
.flaticon-pin:before { content: "\f12f"; }
.flaticon-location:before { content: "\f130"; }
.flaticon-email:before { content: "\f131"; }
.flaticon-call:before { content: "\f132"; }
.flaticon-work-team:before { content: "\f133"; }
.flaticon-men:before { content: "\f134"; }
.flaticon-deadline:before { content: "\f135"; }
.flaticon-badge:before { content: "\f136"; }
.flaticon-manufacture:before { content: "\f137"; }
.flaticon-wheel-alignment:before { content: "\f138"; }
.flaticon-send:before { content: "\f139"; }
.flaticon-fast-time:before { content: "\f13a"; }
.flaticon-play:before { content: "\f13b"; }
.flaticon-customer-service-1:before { content: "\f13c"; }
.flaticon-right-arrow-2:before { content: "\f13d"; }
.flaticon-right-arrow-3:before { content: "\f13e"; }
.flaticon-next:before { content: "\f13f"; }
.flaticon-next-1:before { content: "\f140"; }
.flaticon-settings:before { content: "\f141"; }
.flaticon-menu:before { content: "\f142"; }
.flaticon-maximize:before { content: "\f143"; }
.flaticon-book:before { content: "\f103"; }