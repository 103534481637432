.list-group-item{
    color: #125aac;
  }
  .list-group-item:hover{
    color: #0d34b5;
    text-transform: uppercase;
    border-color: rgb(0, 28, 128);


  }

  .contact-section {
    border-color: #156dd2;

  }

  .MuiButton-containedPrimary{
    background: #8BC34A;
  }

  .FirstStep {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    margin: 0 auto;
    height: 90vh;
    overflow-y: auto;
  }
  .trt{
    /* margin-left: 250px; */
    text-align: center;
    color: #8BC34A;
    border-color: #8BC34A;
  }

  .dropdown-icon {
    float: right;
    margin-left: 25px;
    padding-right: 30px;
    font-size: 32px;
    line-height: inherit
  }



  
ul {
  width: 100%;
  list-style: none;
  padding: 0 0.25rem 0.25rem;
}

ul li::before {
  content: "\200B";
}

.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0 0.5rem 0.5rem;
  margin: 0.25rem 0;
  background-color: #eee;
}

.item:first-child {
  margin: 0;
}

td {
  text-align: center;
  width: 2.5rem;
  width: 48px;
  min-width: 48px;
  height: 2.5rem;
  height: 48px;
  min-height: 48px;
  cursor: pointer;
  margin-right: 0.5rem;
}

.item input[type="checkbox"]:focus + label {
  text-decoration: underline;
}

.item > label {
  font-size: 0.75rem;
  flex-grow: 1;
}

.item svg {
  width: 48px;
  min-width: 48px;
  height: 36px;
  font-size: 1rem;
  color: steelblue;
  cursor: pointer;
}

td svg:focus, 
td svg:hover {
  color: red;
  outline: none;
}