.social-icon {
    display: inline-block;
    margin-right: 10px;
    font-size: 18px;
    /* color: #e7e3e3; */
    color: #1ab5f1;
    transition: color 0.3s ease;
  }
  
  .social-icon:hover {
    color: #007bff;
  }