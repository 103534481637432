.banner-slide {
    position: relative;
    overflow: hidden;
  }
  
  .banner-content {
    position: absolute;
   
    bottom: 50px;
    left: 50px;
    color: white;
    animation: slideInFromRight 1s ease-in-out;
  }
  
  .banner-content h1 {
    font-size: 48px;
    font-weight: bold;
    color: white;
    margin-bottom: 10px;
    animation: slideInFromRight 1s ease-in-out 0.2s forwards;
    opacity: 0;
  }
  
  .banner-content.text {
    font-size: 18px;
    margin-bottom: 30px;
    color: aliceblue;
    animation: slideInFromRight 1s ease-in-out 0.4s forwards;
    opacity: 0;
  }
  
  .banner-image {
    margin-top: 126px;
    width: 100%;
    height: 70vh;
    object-fit: cover;
    animation: slideInFromLeft 0.5s ease-in-out;
  }
  
  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }