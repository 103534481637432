.container {
    height: auto; /* Allow height to adjust based on content */
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Align items at the start */
    padding: 20px; /* Add padding to the container */
  }
  
  h2 {
    margin-top: 20px;
    color: black;
    margin-bottom: 30px;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive columns */
    gap: 40px; /* Space between quadrants */
    width: 80%; /* Adjust the width as needed */
    margin-bottom: 100px;
  }
  
  .quadrant {
    border: 1px solid #ccc; /* Light border for visibility */
    padding: 20px;
    box-shadow: 10px 10px 8px rgba(10, 175, 225, 0.825);
    border-radius: 8px;
    background-color: #f9f9f9; /* Light background */
  }