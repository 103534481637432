
.modal {
    position: relative; /* Use fixed positioning */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Dimmed background */
    display: flex; /* Use flexbox to center */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .modal-content {
    background: white; /* White background for content */
    padding: 20px; /* Padding around content */
    border-radius: 8px; /* Rounded corners */
    width: 500px; /* Fixed width */
    max-width: 90%; /* Responsive max width */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); /* Shadow for depth */
  }