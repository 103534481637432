/* Default Color File  */




/* Color */
a:hover,
a {
	color: #1ab5f1;
}
a:hover
 {
	color: #eceff1;
}

.scroll-to-top:hover,
.preloader-close,
.search-popup .search-form fieldset input[type="submit"] {
	background-color: #1ab5f1;
}

.btn-style-one {
    background-color: #1ab5f1;
}

.header-top .left-column .text {
    background-color: #070e52;
}

.main-menu .navigation>li:before {
    background-color: #1ab5f1;
    
}

.main-menu .navigation>li.current>a {
    color: #1ab5f1;

}

.main-menu .navigation>li>ul>li>a:hover {
    background-color: #1ab5f1;
    
}

.main-menu .navigation>li>ul>li>ul>li>a:hover {
    background-color: #1ab5f1;
    
}

.mobile-menu .menu-backdrop {
    background-color: #1ab5f1;
}

.hidden-sidebar .news-widget .category {
    color: #1ab5f1;
}

.sec-title h2:before {
    background-color: #1ab5f1;
}

.about-section .year-experience {
    color: #1ab5f1;
}

.service-block-one .inner-box {
    border-bottom: 3px solid #1ab5f1;
}

.service-block-one a {
    color: #1ab5f1;
}

.features-section {
    background-color: #1ab5f1;
}

.why-choose-us .icon-box .icon {
    color: #1ab5f1;
}

.video-section h5:before {
    background-color: #1ab5f1;
}

.video-section .video-box .video-btn a {
    color: #1ab5f1;
}

.video-section .video-box .video-btn a:hover {
    background-color: #1ab5f1;
}

.team-block-one h4:before {
    background-color: #1ab5f1;
}

.team-block-one .social-links li a:hover {
    color: #1ab5f1;
}

.testimonial-section .quote {
    color: #1ab5f1;
}

.testimonial-section .owl-dots .owl-dot:hover,
.testimonial-section .owl-dots .owl-dot.active{
    background-color:#1ab5f1;
    border-color:#1ab5f1;
}

.estimate-section .wrapper-box:before {
    background-color: #1ab5f1;
}

.estimate-form .form-group .theme-btn:hover {
    color: #1ab5f1;
}

.facts-section h2 span {
    color: #1ab5f1;
}

.facts-section .facts-block .icon {
    color: #1ab5f1;
}

.blog-section .top-content .theme-btn {
    color: #1ab5f1;
}

.news-block-one .meta-info a:before {
    background-color: #1ab5f1;
}

.news-block-one .link-btn a {
    background-color: #1ab5f1;
}

.cta-section .wrapper-box {
    background-color: #1ab5f1;
}

.main-footer .footer-info-box .icon {
    color: #1ab5f1;
}

.widget_newsletter form button {
    background-color: #1ab5f1;
}

.widget_newsletter .social-links li a:hover {
    background-color: #1ab5f1;
    border-color: #1ab5f1;
}

.main-header.header-style-two .header-upper.style-two .logo-box .logo {
    background-color: #1ab5f1;
}

.service-block-two h4:after {
    background-color: #1ab5f1;
}

.why-choose-us.style-two {
    background-color: #1ab5f1;
}

.cta-section.style-two {
    background-color: #1ab5f1;
}

.cta-section.style-two .theme-btn {
    background: #1ab5f1;
}

.testimonial-section.style-two .testimonial-block .designation {
    color: #1ab5f1;
}
/* Team Style Two */
.team-section.style-two {
    background-color: #f5f5f5;
}

.estimate-section.style-two {
    background-color: #1ab5f1;
}

.testimonial-section.style-two .owl-dots .owl-dot {
    border-color: #1ab5f1;
}

.main-header.header-style-three .header-upper.style-three .logo-box:before {
    background-color: #1ab5f1;
}

.welcome-block .left-column {
    background-color: #1ab5f1;
}

.features-section-two {
    background-color: #1ab5f1;
}

.cta-section.style-three {
    background-color: #1ab5f1;
}

.project-block-one h4 {
    background-color: #1ab5f1;
}

.why-choose-us-two {
    background-color: #1ab5f1;
}

.history-block .years {
    background: #1ab5f1;
}

.sidebar-title h2:after{
    background-color: #1ab5f1;
}

.blog-cat li.active a,
.blog-cat li a:hover{
    background-color: #1ab5f1;
}

.contact-info-widget .inner-box ul li .icon{
    color: #1ab5f1;
}

.featured-block .featured-inner .content .icon-box{
    background: #1ab5f1;
}

.featured-block .featured-inner .content h3 a:hover{
    color: #1ab5f1;
}

.accordion-box .block .acc-btn.active .icon-outer .icon-minus{
    background-color:#1ab5f1;
}

.filter-tabs li.active {
    color: #1ab5f1;
    border-color: #1ab5f1;
}

.filter-tabs.style-two li.active:before {
    background-color: #1ab5f1;
}

.testimonial-section.style-three .testimonial-block.style-two .designation {
    color: #1ab5f1;
}

.error-section h1{
    color:#1ab5f1;
}

.error-section .theme-btn:hover{
    color:#1ab5f1;
}

.comming-soon .content .content-inner .text span {
    color: #1ab5f1;
}

.news-block-two .date {
    color: #1ab5f1;
}

.news-block-two h3 a:hover {
    color: #1ab5f1;
}

.sidebar .search-box .form-group input[type="text"],
.sidebar .search-box .form-group input[type="search"]{
    background: #1ab5f1;
}

.sidebar-title h2:after{
    background-color:#1ab5f1;
}

.cat-list li a:hover{
    color:#1ab5f1;
}

.sidebar .popular-posts .post a:hover{
    color: #1ab5f1;
}

.sidebar .popular-posts .post-info{
    color: #1ab5f1;
}

.sidebar .popular-tags a:hover{
    border-color:#1ab5f1;
    background-color:#1ab5f1;
}

.sidebar-page-container .comments-area .comment-box .post-info li a{
    color: #1ab5f1;
}

.comment-form .form-group input[type="text"]:focus,
.comment-form .form-group input[type="email"]:focus,
.comment-form .form-group textarea:focus{
    border-color: #1ab5f1;
}

.contact-section .contact-title h2:after{
    background-color: #1ab5f1;
}

.contact-form input[type="text"]:focus,
.contact-form input[type="email"]:focus,
.contact-form input[type="password"]:focus,
.contact-form select:focus,
.contact-form textarea:focus{
    border-color: #1ab5f1;
}

.contact-section .info-column ul li i {
    color: #1ab5f1;
}

.service-block-two h4 a:hover {
    color: #1ab5f1;
}














